<script setup>
import Layout from "../../layouts/main.vue";

import { onMounted, reactive, ref, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
import MethodService from "../../service/MethodService";
import Validate from "../../service/ValidService";
import DataForm from "./dataForm-cau-hinh-tram";
import Swal from "sweetalert2";
import toastr from "toastr";
import { FormInstance } from "element-plus";
import { profileUserStore } from "../../stores/profileUser";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const ruleFormRef = ref(FormInstance);
const profileUser = profileUserStore();
import mushroom from "cem-primary-api";
const router = useRouter();
const route = useRoute();

// lấy ngôn ngữ từ pinia store

import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const rolesUser = computed(() => {
  return profileUser.roles;
});
let loading = ref(true); // loading
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
const pageSizeDefault = ref(20); // số bản ghi mặc định ở phân trang
const rulesForm = reactive({
  code: [Validate.required, Validate.checkCode],
  title: [Validate.required],
});
//hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
// tìm kiếm
const fn_TimKiem = () => {
  if (tableRules.dataSearch.value.code)
    tableRules.dataSearch.value.code = tableRules.dataSearch.value.code.trim();
  if (tableRules.dataSearch.value.title)
    tableRules.dataSearch.value.title =
      tableRules.dataSearch.value.title.trim();
  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );

  tableRules.offset = 0;
  tableRules.sort = "";
  fn_LayDanhSachProbeColumn();
};
// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  fn_LayDanhSachProbeColumn();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  fn_LayDanhSachProbeColumn();
};

const fn_hashUrl = (routeQuery, table_rules) => {
  routeQuery = route.query; // không phải router
  table_rules = tableRules;
  MethodService.hashUrl(routeQuery, table_rules);
  pageSizeDefault.value = tableRules.limit;
};

// chuyển sang màn thêm mới tài khoản
const fn_ThemMoiCauHinhTram = () => {
  router.push({ name: "ThemMoiCauHinhTram" });
};

// lấy toàn bộ danh sách probe_column_c
const fn_LayDanhSachProbeColumn = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  router
    .replace({
      name: "DanhSachProbeColumn",
      query: {
        limit: tableRules.limit,
        page: tableRules.page,
        sort: tableRules.sort,
        filters: tableRules.filters,
        showFormSearch: tableRules.showFormSearch,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.probe_column_config.listAsync(dataFilter);
    if (response.result) {
      dataTables.value = response.result;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};
const fn_handle = (type, scope, row) => {
  if (type == "update") {
    router.push({
      name: "CapNhatCauHinhTram",
      params: { id: row.id },
    });
  }
  if (type == "view") {
    router.push({
      name: "ChiTietCauHinhTram",
      params: { id: row.id },
    });
  }
  if (type == "delete") {
    Swal.fire({
      title: t("t_warning"),
      text: t("t_probe_config_delete"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      cancelButtonText: t("swal_cancel"),
      confirmButtonText: t("swal_accept"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.probe_column_config.deleteAsync({
            id: row.id,
          });
          if (response.result) {
            toastr.success(t("toastr_delete_success"));
            fn_LayDanhSachProbeColumn();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e);
        }
      }
    });
  }
};

watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    rulesForm.value = MethodService.copyObject(rulesForm);
  }
);

onMounted(async () => {
  fn_hashUrl();
  fn_LayDanhSachProbeColumn();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t-option-info-probe") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              v-if="rolesUser === 'Admin' || rolesUser === 'AdminPrimary'"
              class="btn btn-secondary btn-border"
              @click="fn_ThemMoiCauHinhTram"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-code')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['code']"
                          :placeholder="$t('t-place-code')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-title')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['title']"
                          :placeholder="$t('t-place-title')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      native-type="submit"
                      style="width: 100px"
                      @click="fn_TimKiem()"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            min-height="550"
            v-loading="loading"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
            :empty-text="$t('t-no-data')"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="code"
              :label="$t('t-code')"
              min-width="200"
              align="start"
              :sortable="true"
            />
            <el-table-column
              prop="title"
              min-width="200"
              :label="$t('t-title')"
              align="start"
              :sortable="true"
            />
            <el-table-column
              :label="$t('t-action')"
              align="center"
              width="150px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_view')"
                    placement="top"
                    :enterable="false"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-info waves-effect waves-light btn-sm"
                      @click="fn_handle('view', scope.$index, scope.row)"
                    >
                      <i class="ri-eye-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_option')"
                    placement="top"
                    :enterable="false"
                  >
                    <button
                      v-if="
                        rolesUser === 'Admin' || rolesUser === 'AdminPrimary'
                      "
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_delete')"
                    placement="top"
                    :enterable="false"
                  >
                    <button
                      v-if="
                        rolesUser === 'Admin' || rolesUser === 'AdminPrimary'
                      "
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
